import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UploadLimit, WorkspacesService } from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceService,
} from '@sweetpopcorn/common';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { StripeService } from 'ngx-stripe';
import { PrimeNGConfig } from 'primeng/api';
import { validateCountryIsAustria } from '../validators/austiaValidator';

@Component({
  selector: 'sweetpopcorn-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit {
  maxDescriptionLength = 1000;
  saving = false;
  progress = false;
  workspaceId: string;
  selectedFile: File;
  img: Blob;
  workSpaceNotificationMessage = { title: '', message: '' };
  slugNotificationMessage = { title: '', message: '' };
  activeIndex = 0;
  privateUser = false;
  imageChangedEvent: any = '';

  countries = [];
  selectedCountry: any;
  uoloadLimit = [];

  private reg = 'https://www\\.[a-zA-Z0-9-]+(?:\\.[a-zA-Z]{2,})+(?:/[^\\s]*)?';

  companyForm = this.fb.group({
    companyName: ['', Validators.required],
    fin: ['', Validators.required],
    ownerMail: ['', [Validators.required, Validators.email]],
  });

  addressForm = this.fb.group({
    addressrow1: ['', Validators.required],
    addressrow2: [''],
    plz: ['', Validators.pattern('[0-9]{4,4}')],
    city: ['', Validators.required],
    country: ['', [validateCountryIsAustria()]],
  });

  privateForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    birthDate: [''],
    ownerMail: [''],
  });

  form = this.fb.group({
    id: [''],
    planId: [''],
    name: ['', Validators.required],
    customSlug: [''],
    description: [''],
    descriptionEn: [''],
    contactUrl: ['', Validators.pattern(this.reg)],
    contactPhone: [''],
    password: [''],
    image: ['', Validators.required],
    uploadLimit: [UploadLimit.NoLimit],
  });

  constructor(
    private fb: FormBuilder,
    private workspacesService: WorkspacesService,
    private workspaceService: WorkspaceService,
    private notificationToastService: NotificationToastService,
    private stripeService: StripeService,
    private router: Router,
    private translocoService: TranslocoService,
    private config: PrimeNGConfig
  ) {}

  ngOnInit() {
    this.translocoService.langChanges$.subscribe((value) => {
      this.translocoService.selectTranslate('country-austria').subscribe(() => {
        this.setSlugNotification();
        this.translateNgPrime(value);
        this.setItems();
      });
    });
  }

  createWorkspace() {
    const formData = this.form.getRawValue();
    const privateData = this.privateForm.getRawValue();
    const companyData = this.companyForm.getRawValue();
    const addressData = this.addressForm.getRawValue();
    const mail = privateData?.ownerMail
      ? privateData.ownerMail
      : companyData.ownerMail;
    this.saving = true;
    const slugName = formData.customSlug ? formData.customSlug : formData.name;
    let isoDate = '';
    if (privateData?.birthDate) {
      // Get the date from the form
      const dateOfBirth = new Date(privateData?.birthDate);

      // Adjust for the timezone offset to ensure the date is correct in local time
      const adjustedDate = new Date(
        dateOfBirth.getTime() - dateOfBirth.getTimezoneOffset() * 60000
      );

      // Convert to ISO string and take the first 10 characters (YYYY-MM-DD)
      isoDate = adjustedDate.toISOString().substring(0, 10);
    }
    this.workspacesService
      .getCheckWorkspaceSlug(slugName)
      .subscribe((value) => {
        if (value.isAvailable) {
          this.workspacesService
            .createWorkspace(
              formData?.name,
              slugName,
              formData?.description,
              formData?.descriptionEn,
              formData?.contactUrl,
              mail,
              formData?.contactPhone,
              formData?.password,
              this.img,
              formData?.uploadLimit,
              privateData?.firstName,
              privateData?.lastName,
              isoDate,
              addressData?.addressrow1,
              addressData?.addressrow2,
              addressData?.plz,
              addressData?.city,
              companyData?.companyName,
              companyData?.fin,
              '4ec977c2-77cf-486d-8e14-a4f9a0d87d58'
            )
            .subscribe(async (workspace) => {
              this.workspaceService.reloadWorkspaces();
              this.notificationToastService.successMessage(
                this.workSpaceNotificationMessage.title,
                `${this.form.get('name').value}` +
                  ' ' +
                  this.workSpaceNotificationMessage.message
              );
              this.form.reset();
              this.saving = false;
              await this.router.navigate(['workspace', workspace.id]);
            });
        } else {
          this.notificationToastService.errorMessage(
            this.slugNotificationMessage.title,
            this.slugNotificationMessage.message
          );
          this.saving = false;
        }
      });
  }

  setSlugNotification() {
    this.slugNotificationMessage.title =
      this.translocoService.translate('slug-name');
    this.slugNotificationMessage.message =
      this.translocoService.translate('slug-error');
    this.workSpaceNotificationMessage.title = this.translocoService.translate(
      'info-workspaceCreated'
    );
    this.workSpaceNotificationMessage.message = this.translocoService.translate(
      'info-workspaceCreatedDetail'
    );
  }

  changeValidators() {
    if (this.privateUser) {
      this.companyForm.controls['companyName'].clearValidators();
      this.companyForm.controls['companyName'].setValue(null);
      this.companyForm.controls['fin'].clearValidators();
      this.companyForm.controls['fin'].setValue(null);
      this.companyForm.controls['ownerMail'].clearValidators();
      this.companyForm.controls['ownerMail'].setValue(null);

      this.privateForm.controls['firstName'].setValidators([
        Validators.required,
      ]);
      this.privateForm.controls['lastName'].setValidators([
        Validators.required,
      ]);
      // this.privateForm.controls['birthDate'].setValidators([Validators.required]);
      this.privateForm.controls['ownerMail'].setValidators([
        Validators.required,
        Validators.email,
      ]);

      this.companyForm.controls['companyName'].updateValueAndValidity();
      this.companyForm.controls['fin'].updateValueAndValidity();
      this.privateForm.controls['firstName'].updateValueAndValidity();
      this.privateForm.controls['lastName'].updateValueAndValidity();
      // this.privateForm.controls['birthDate'].updateValueAndValidity();
      this.privateForm.controls['ownerMail'].updateValueAndValidity();
    } else {
      this.privateForm.controls['firstName'].clearValidators();
      this.privateForm.controls['firstName'].setValue(null);
      this.privateForm.controls['lastName'].clearValidators();
      // this.privateForm.controls['birthDate'].clearValidators();
      this.privateForm.controls['lastName'].setValue(null);
      this.privateForm.controls['ownerMail'].clearValidators();
      this.privateForm.controls['ownerMail'].setValue(null);

      this.companyForm.controls['companyName'].setValidators([
        Validators.required,
      ]);
      this.companyForm.controls['ownerMail'].setValidators([
        Validators.required,
        Validators.email,
      ]);
      this.companyForm.controls['fin'].setValidators([Validators.required]);

      this.companyForm.controls['companyName'].updateValueAndValidity();
      this.companyForm.controls['fin'].updateValueAndValidity();
      this.privateForm.controls['firstName'].updateValueAndValidity();
      this.privateForm.controls['lastName'].updateValueAndValidity();
      // this.privateForm.controls['birthDate'].updateValueAndValidity();
      this.privateForm.controls['ownerMail'].updateValueAndValidity();
    }
  }

  changeTab(value: number) {
    this.activeIndex = value;
  }

  private setItems() {
    this.countries = [
      {
        name: this.translocoService.translate('country-austria'),
        code: 'AT',
        id: '4ec977c2-77cf-486d-8e14-a4f9a0d87d58',
      },
    ];

    this.selectedCountry = this.countries.find((o) => o.code === 'AT');
    this.addressForm.get('country').setValue(this.selectedCountry);

    this.uoloadLimit = [
      {
        name: this.translocoService.translate('upload-nolimit'),
        value: UploadLimit.NoLimit,
      },
      {
        name: this.translocoService.translate('upload-once'),
        value: UploadLimit.Once,
      },
      {
        name: this.translocoService.translate('upload-daily'),
        value: UploadLimit.Daily,
      },
      {
        name: this.translocoService.translate('upload-weekly'),
        value: UploadLimit.Weekly,
      },
      {
        name: this.translocoService.translate('upload-monthly'),
        value: UploadLimit.Monthly,
      },
      {
        name: this.translocoService.translate('upload-yearly'),
        value: UploadLimit.Yearly,
      },
    ];
  }

  fileChangeEvent(event: any, filename: string): void {
    this.form.get('image').setValue(filename);
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.img = event.blob;
    // event.blob can be used to upload the cropped image
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  private translateNgPrime(value: string) {
    if (value === 'de') {
      this.config.setTranslation({
        dayNames: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fri', 'Sam'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: [
          'Jannuar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dez',
        ],
      });
    } else {
      this.config.setTranslation({
        dayNames: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      });
    }
  }
}
